<template>
 
  <div class="h-auto pl-4 py-5 text-xs md:text-sm 
              lg:text-base xl:text-xl 2xl:text-2xl align-top items-center
              border-b-2 border-neutral-100 ">
    <div class="flex-none w-full pl-10 font-bold text-hcap_blue/80 mb-2"
    >Data</div>
      <div class="flex flex-wrap pl-10">

        
        <div data-id="lulc_1" 
             class="basis-3/6">
          <input type="radio" name="lulc_1" id="lulc_1" 
                 value="lulc_1" data-id="lulc_1" 
                 v-model="selected_overlay">
          <label for="lulc_1"  data-id="lulc_1" 
                 class="pl-2 text-slate-700">Land Use (Level 1)</label>
        </div>
        
        <div data-id="lulc_2" 
             class="basis-3/6">
          <input type="radio" name="lulc_2" id="lulc_3" 
                 value="lulc_2" data-id="lulc_2" 
                 v-model="selected_overlay">
          <label for="lulc_2"  data-id="lulc_2" 
                 class="pl-3 text-slate-700">Land Use (Level 2)</label>
        </div>

        <!-- <div data-id="habitat" 
             class="basis-3/6">
          <input type="radio" name="habitat" id="habitat" disabled 
                 value="habitat" data-id="habitat" v-model="selected_overlay">
          <label for="habitat"  data-id="habitat" 
                 class="pl-2 text-slate-300">Habitat</label>
        </div>
        
        <div data-id="species" 
             class="basis-3/6">
          <input type="radio" name="species" id="species" disabled 
                 value="species" data-id="species" v-model="selected_overlay">
          <label for="species"  data-id="species" 
                 class="pl-2 text-slate-300">Species</label>
        </div> -->
      
      </div>
  </div>

</template>

<script>


  export default {

    name: "OverlayMenu",

    emits:[
      'selected_overlay'
    ],

    data(){
      return {
        selected_overlay: 'lulc_1'
      }
    },

    watch:{

      selected_overlay: function(){
        this.$emit('selected_overlay', this.selected_overlay);
      }
    },

    methods:{
    }
  }

</script>
