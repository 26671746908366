<template>
  
  <div class="flex bg-white w-full md:w-2/5 "
        id="header">
    <div class="h-20 w-full">
      <div class="flex flex-nowrap items-center">
        <div class="bg-white flex flex-nowrap basis-24 md:basis-36 border-b border-hcap_blue/40">
          <img class="w-24 h-24  mx-auto"  :src="landuse_logo_svg">
        </div>
        <div class="flex text-left text-xl md:text-xl lg:text-2xl xl:text-3xl font-bold font-roboto text-hcap_logo_blue">
          <span>NJ DEP Land Use 1986-2020</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import landuse_logo_svg from "../assets/img/njmap_landuse.svg" 

  export default {
    name: 'HomeComponent',

    data() {
      return {
        landuse_logo_svg
      }
    },

    props: {
      msg: String
    }
  }

</script>
