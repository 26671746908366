<template>
 
  <div class="h-auto pl-4 py-5 text-xs md:text-sm 
              lg:text-base xl:text-xl 2xl:text-2xl align-top items-center">
    <div class="flex-none w-full pl-10 font-bold text-hcap_blue/80 mb-2"
    >Version</div>
      <div class="flex flex-wrap pl-20 pr-14">
        <div class="basis-1/6"></div>
        <div data-id="v86" 
             class="basis-2/6">
          <input type="radio" name="version" id="v86" 
                 value="86" data-id="v86" 
                 v-model="selected_version">
          <label for="v86"  data-id="v86" 
                 class="pl-2 text-slate-700">1986</label>
        </div>
        <div data-id="v95" 
             class="basis-2/6">
          <input type="radio" name="version" id="v95" 
                 value="95" data-id="v95" 
                 v-model="selected_version">
          <label for="v95"  data-id="v95" 
                 class="pl-2 text-slate-700">1995</label>
        </div>
        <div class="basis-1/6"></div>
        <div class="basis-1/6"></div>
        <div data-id="v02" 
             class="basis-2/6">
          <input type="radio" name="version" id="v02" 
                 value="2" data-id="v02" 
                 v-model="selected_version">
          <label for="v02"  data-id="v02" 
                 class="pl-2 text-slate-700">2002</label>
        </div>
        <div data-id="v07" 
             class="basis-2/6">
          <input type="radio" name="version" id="v07" 
                 value="7" data-id="v07" 
                 v-model="selected_version">
          <label for="v07"  data-id="v07" 
                 class="pl-2 text-slate-700">2007</label>
        </div>
        <div class="basis-1/6"></div>
        <div class="basis-1/6"></div>
        <div data-id="v12" 
             class="basis-2/6">
          <input type="radio" name="version" id="v12" 
                 value="12" data-id="v12" 
                 v-model="selected_version">
          <label for="v12"  data-id="v12" 
                 class="pl-2 text-slate-700">2012</label>
        </div>
        <div data-id="v15" 
             class="basis-2/6">
          <input type="radio" name="version" id="v15" 
                 value="15" data-id="v15" 
                 v-model="selected_version">
          <label for="v15"  data-id="v15" 
                 class="pl-2 text-slate-700">2015</label>
        </div>
        <div class="basis-1/6"></div>
        <div class="basis-1/6"></div>
        <div data-id="v20" 
             class="basis-2/6">
          <input type="radio" name="version" id="v20" 
                 value="20" data-id="v20" 
                 v-model="selected_version">
          <label for="v20"  data-id="v20" 
                 class="pl-2 text-slate-700">2020</label>
        </div>
      </div>
  </div>

</template>

<script>

  export default {

    name: "VersionMenu",

    emits:[
      'lulc_version'
    ],

    data(){
      return{
          'selected_version': '20',
        }
    },

    watch:{

      'selected_version': function(){
        this.$emit('lulc_version', this.selected_version);
      }
    },
  }

</script>
